.card {
    position: relative;
    width: 500px;
    height: 500px;
    background: lightgrey;
    border-radius: 30px;
    overflow: hidden;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: all 1s ease-in-out;
  }

  @media screen and (max-width: 700px) {
    .card {
      width: 350px;
      height: 350px;
      margin: 20px;
    }
    
  }
  
  .background {
    position: absolute;
    inset: 0;
    background: radial-gradient(circle at 100% 107%, #ff89cc 0%, #9cb8ec 30%, #00ffee 60%, #62c2fe 100%);
  }
  
  .logoimg {
    position: absolute;
    right: 50%;
    bottom: 50%;
    transform: translate(50%, 50%);
    transition: all 0.6s ease-in-out;
  }
  
  .logoimg .logo-svg {
    fill: white;
    width: 30px;
    height: 30px;
  }
  
  .icon {
    display: inline-block;
    width: 20px;
    height: 20px;
  }
  
  .icon .svg {
    fill: rgba(255, 255, 255, 0.797);
    width: 100%;
    transition: all 0.5s ease-in-out;
  }
  
  .box {
    position: absolute;
    padding: 10px;
    text-align: right;
    background: rgba(255, 255, 255, 0.389);
    border-top: 2px solid rgb(255, 255, 255);
    border-right: 1px solid white;
    border-radius: 10% 13% 42% 0%/10% 12% 75% 0%;
    box-shadow: rgba(100, 100, 111, 0.364) -7px 7px 29px 0px;
    transform-origin: bottom left;
    transition: all 1s ease-in-out;
  }
  
  .box::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
  
  .box:hover .svg {
    fill: white;
  }
  
  .box1 {
    width: 80%;
    height: 80%;
    margin: 10px;

    bottom: -50%;
    left: -50%;
  }
  
  .box1::before {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #ff53d4 60%, #62c2fe 90%);
  }
  
  .box1:hover::before {
    opacity: 1;
  }
  
  .box1:hover .icon .svg {
    filter: drop-shadow(0 0 5px white);
  }
  
  .box2 {
    width: 70%;
    height: 70%;
    bottom: -50%;
    margin: 10px;

    left: -50%;
    transition-delay: 0.2s;
  }
  
  .box2::before {
    background: radial-gradient(circle at 30% 107%, #91e9ff 0%, #00ACEE 90%);
  }
  
  .box2:hover::before {
    opacity: 1;
  }
  
  .box2:hover .icon .svg {
    filter: drop-shadow(0 0 5px white);
  }
  
  .box3 {
    width: 60%;
    height: 60%;
    bottom: -50%;
    margin: 10px;
    left: -50%;
    transition-delay: 0.4s;
  }
  
  .box3::before {
    background: radial-gradient(circle at 30% 107%, #969fff 0%, #b349ff 90%);
  }
  
  .box3:hover::before {
    opacity: 1;
  }
  
  .box3:hover .icon .svg {
    filter: drop-shadow(0 0 5px white);
  }
  
  .box4 {
    width: 10%;
    height: 10%;
    bottom: -10%;
    left: -10%;
    transition-delay: 0.6s;
  }
  
  .card:hover {
    transform: scale(1.1);
  }
  
  .card:hover .box {
    bottom: -1px;
    left: -1px;
  }
  
  .card:hover .logoimg {
    transform: translate(0, 0);
    bottom: 20px;
    right: 20px;
  }



  /* tip  */
  .item-hints {
  --purple: #720c8f;
  cursor: pointer;

  display: flex;
  display: block;
  justify-content: flex-start;
  padding-right: 170px;
}

@media (max-width: 768px) {
  .display {
    display: none !important;
  }
}

.item-hints .hint {
  margin: 150px auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.item-hints .hint-dot {
  z-index: 3;
  border: 1px solid #ffe4e4;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-transform: translate(-0%, -0%) scale(0.95);
  transform: translate(-0%, -0%) scale(0.95);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.item-hints .hint-radius {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -125px 0 0 -125px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.item-hints .hint[data-position="1"] .hint-content {
  top: 85px;
  left: 50%;
  margin-left: 56px;
}
.item-hints .hint-content {
  width: 300px;
  position: absolute;
  z-index: 5;
  padding: 35px 0;
  opacity: 0;
  transition: opacity 0.7s ease, visibility 0.7s ease;
  color: #fff;
  visibility: hidden;
  pointer-events: none;
}
.item-hints .hint:hover .hint-content {
  position: absolute;
  z-index: 5;
  padding: 35px 0;
  opacity: 1;
  -webkit-transition: opacity 0.7s ease, visibility 0.7s ease;
  transition: opacity 0.7s ease, visibility 0.7s ease;
  color: #fff;
  visibility: visible;
  pointer-events: none;
}
.item-hints .hint-content::before {
  width: 0px;
  bottom: 29px;
  left: 0;
  content: "";
  background-color: #fff;
  height: 1px;
  position: absolute;
  transition: width 0.4s;
}
.item-hints .hint:hover .hint-content::before {
  width: 180px;
  transition: width 0.4s;
}
.item-hints .hint-content::after {
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transform: rotate(-225deg);
  transform: rotate(-225deg);
  bottom: 29px;
  left: 0;
  width: 80px;
  content: "";
  background-color: #fff;
  height: 1px;
  position: absolute;
  opacity: 1;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.item-hints .hint:hover .hint-content::after {
  opacity: 1;
  visibility: visible;
}
.item-hints .hint[data-position="4"] .hint-content {
  bottom: 85px;
  left: 50%;
  margin-left: 56px;
}
