.form-container {
    width: 400px;
    background: linear-gradient(#212121, #212121) padding-box,
                linear-gradient(145deg, transparent 35%,#e81cff, #40c9ff) border-box;
    border: 2px solid transparent;
    padding: 32px 24px;
    font-size: 14px;
    font-family: inherit;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
    border-radius: 16px;
  }
  

  @media screen  and (max-width: 720px){
    .form-container {
        width: 350px;
    }
  }
  
  .form-container button:active {
    scale: 0.95;
  }
  
  .form-container .form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-container .form-group {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  
  .form-container .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #717171;
    font-weight: 600;
    font-size: 12px;
  }
  
  .form-container .form-group input {
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    color: #fff;
    font-family: inherit;
    background-color: transparent;
    border: 1px solid #414141;
  }
  
  .form-container .form-group textarea {
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    resize: none;
    color: #fff;
    height: 96px;
    border: 1px solid #414141;
    background-color: transparent;
    font-family: inherit;
  }
  
  .form-container .form-group input::placeholder {
    opacity: 0.5;
  }
  
  .form-container .form-group input:focus {
    outline: none;
    border-color: #e81cff;
  }
  
  .form-container .form-group textarea:focus {
    outline: none;
    border-color: #e81cff;
  }
  
  .form-container .form-submit-btn {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: flex-start;
    font-family: inherit;
    color: #717171;
    font-weight: 600;
    width: 40%;
    background: #313131;
    border: 1px solid #414141;
    padding: 12px 16px;
    font-size: inherit;
    gap: 8px;
    margin-top: 8px;
    cursor: pointer;
    border-radius: 6px;
  }
  
  .form-container .form-submit-btn:hover {
    background-color: #fff;
    border-color: #fff;
  }
  
  
  
  .tooltip-container {
    height: 70px;
    width: 110px;
    border-radius: 5px;
    background-color: #fff;
    background-image: linear-gradient(
      to left bottom,
      #f2f5f8,
      #ecf1f2,
      #e7eceb,
      #e3e7e4,
      #e1e2de
    );
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.151);
    position: relative;
    transition: transform 0.3s ease;
  }
  
  .tooltip-container::before {
    position: absolute;
    content: "";
    top: -50%;
    clip-path: polygon(50% 0, 0 100%, 100% 100%);
    border-radius: 5px;
    background-color: fff;
    background-image: linear-gradient(
      to left bottom,
      #f2f5f8,
      #e4eaec,
      #d8dfde,
      #cdd3cf,
      #c5c7c1
    );
    width: 100%;
    height: 50%;
    transform-style: preserve-3d;
    transform: perspective(1000px) rotateX(-150deg) translateY(-110%);
    transition: transform 0.3s ease;
  }
  
  .tooltip-container .text {
    color: rgb(32, 30, 30);
    font-weight: bold;
    font-size: 40px;
  }
  
  .tooltip {
    position: absolute;
    top: -20px;
    opacity: 0;
    background: linear-gradient(-90deg, rgba(255, 255, 255, 0.05) 1px, rgb(255, 255, 255) 1px),
      linear-gradient(rgba(0, 0, 0, 0.05) 1px, white 1px),
      linear-gradient(-90deg, rgba(0, 0, 0, 0.04) 1px, white 1px),
      linear-gradient(rgba(0, 0, 0, 0.04) 1px, white 1px),
      linear-gradient(rgb(204, 6, 211) 3px, #f2f2f2 3px, #f2f2f2 78px, white 78px),
      linear-gradient(-90deg, #aaa 1px, white 1px),
      linear-gradient(-90deg, white 3px, #f2f2f2 3px, #f2f2f2 78px, white 78px),
      linear-gradient(#aaa 1px, white 1px), #f2f2f2;
    background-size: 4px 4px, 4px 4px, 80px 80px, 80px 80px, 80px 80px, 80px 80px,
      80px 80px, 80px 80px;
    padding: 5px 10px;
    border: 1px solid rgb(206, 19, 150);
  
    height: 70px;
    width: 110px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.2s;
    pointer-events: none;
    letter-spacing: 0.5px;
    font-size: 18px;
    font-weight: 600;
    text-shadow: 10px salmon;
  }
  .tooltip-container:hover {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .tooltip-container:hover::before {
    transform: rotateY(0);
    background-image: none;
    background-color: rgb(192, 11, 228);
  }
  
  .tooltip-container:hover .tooltip {
    top: -90px;
    opacity: 1;
    transition-duration: 0.3s;
  }
  